import * as i0 from '@angular/core';
import { InjectionToken, Optional, Inject, Injectable, SkipSelf, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpHeaders, HttpContext, HttpParams } from '@angular/common/http';

/**
 * Custom HttpParameterCodec
 * Workaround for https://github.com/angular/angular/issues/18261
 */
class CustomHttpParameterCodec {
  encodeKey(k) {
    return encodeURIComponent(k);
  }
  encodeValue(v) {
    return encodeURIComponent(v);
  }
  decodeKey(k) {
    return decodeURIComponent(k);
  }
  decodeValue(v) {
    return decodeURIComponent(v);
  }
}
const BASE_PATH = new InjectionToken('basePath');
const COLLECTION_FORMATS = {
  'csv': ',',
  'tsv': '   ',
  'ssv': ' ',
  'pipes': '|'
};
class Configuration {
  /**
   *  @deprecated Since 5.0. Use credentials instead
   */
  apiKeys;
  username;
  password;
  /**
   *  @deprecated Since 5.0. Use credentials instead
   */
  accessToken;
  basePath;
  withCredentials;
  /**
   * Takes care of encoding query- and form-parameters.
   */
  encoder;
  /**
   * Encoding of various path parameter
   * <a href="https://github.com/OAI/OpenAPI-Specification/blob/main/versions/3.1.0.md#style-values">styles</a>.
   * <p>
   * See {@link README.md} for more details
   * </p>
   */
  encodeParam;
  /**
   * The keys are the names in the securitySchemes section of the OpenAPI
   * document. They should map to the value used for authentication
   * minus any standard prefixes such as 'Basic' or 'Bearer'.
   */
  credentials;
  constructor(configurationParameters = {}) {
    this.apiKeys = configurationParameters.apiKeys;
    this.username = configurationParameters.username;
    this.password = configurationParameters.password;
    this.accessToken = configurationParameters.accessToken;
    this.basePath = configurationParameters.basePath;
    this.withCredentials = configurationParameters.withCredentials;
    this.encoder = configurationParameters.encoder;
    if (configurationParameters.encodeParam) {
      this.encodeParam = configurationParameters.encodeParam;
    } else {
      this.encodeParam = param => this.defaultEncodeParam(param);
    }
    if (configurationParameters.credentials) {
      this.credentials = configurationParameters.credentials;
    } else {
      this.credentials = {};
    }
    // init default Bearer JWT Token credential
    if (!this.credentials['Bearer JWT Token']) {
      this.credentials['Bearer JWT Token'] = () => {
        return typeof this.accessToken === 'function' ? this.accessToken() : this.accessToken;
      };
    }
  }
  /**
   * Select the correct content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param contentTypes - the array of content types that are available for selection
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderContentType(contentTypes) {
    if (contentTypes.length === 0) {
      return undefined;
    }
    const type = contentTypes.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return contentTypes[0];
    }
    return type;
  }
  /**
   * Select the correct accept content-type to use for a request.
   * Uses {@link Configuration#isJsonMime} to determine the correct accept content-type.
   * If no content type is found return the first found type if the contentTypes is not empty
   * @param accepts - the array of content types that are available for selection.
   * @returns the selected content-type or <code>undefined</code> if no selection could be made.
   */
  selectHeaderAccept(accepts) {
    if (accepts.length === 0) {
      return undefined;
    }
    const type = accepts.find(x => this.isJsonMime(x));
    if (type === undefined) {
      return accepts[0];
    }
    return type;
  }
  /**
   * Check if the given MIME is a JSON MIME.
   * JSON MIME examples:
   *   application/json
   *   application/json; charset=UTF8
   *   APPLICATION/JSON
   *   application/vnd.company+json
   * @param mime - MIME (Multipurpose Internet Mail Extensions)
   * @return True if the given MIME is JSON, false otherwise.
   */
  isJsonMime(mime) {
    const jsonMime = new RegExp('^(application\/json|[^;/ \t]+\/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i');
    return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
  }
  lookupCredential(key) {
    const value = this.credentials[key];
    return typeof value === 'function' ? value() : value;
  }
  defaultEncodeParam(param) {
    // This implementation exists as fallback for missing configuration
    // and for backwards compatibility to older typescript-angular generator versions.
    // It only works for the 'simple' parameter style.
    // Date-handling only works for the 'date-time' format.
    // All other styles and Date-formats are probably handled incorrectly.
    //
    // But: if that's all you need (i.e.: the most common use-case): no need for customization!
    const value = param.dataFormat === 'date-time' && param.value instanceof Date ? param.value.toISOString() : param.value;
    return encodeURIComponent(String(value));
  }
}

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class CommuneControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  get3(getCommunesRequest, observe = 'body', reportProgress = false, options) {
    if (getCommunesRequest === null || getCommunesRequest === undefined) {
      throw new Error('Required parameter getCommunesRequest was null or undefined when calling get3.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/commune/get`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getCommunesRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getById1(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getById1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/commune/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "string",
      dataFormat: undefined
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getTerytCommuneById(getTerytCommuneByIdRequest, observe = 'body', reportProgress = false, options) {
    if (getTerytCommuneByIdRequest === null || getTerytCommuneByIdRequest === undefined) {
      throw new Error('Required parameter getTerytCommuneByIdRequest was null or undefined when calling getTerytCommuneById.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/commune/getTerytCommuneById`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getTerytCommuneByIdRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchTerytCommune(searchTerytCommuneRequest, observe = 'body', reportProgress = false, options) {
    if (searchTerytCommuneRequest === null || searchTerytCommuneRequest === undefined) {
      throw new Error('Required parameter searchTerytCommuneRequest was null or undefined when calling searchTerytCommune.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/commune/searchTerytCommune`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchTerytCommuneRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function CommuneControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CommuneControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CommuneControllerService,
    factory: CommuneControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CommuneControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class CompanyControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  get2(getCompanyRequest, observe = 'body', reportProgress = false, options) {
    if (getCompanyRequest === null || getCompanyRequest === undefined) {
      throw new Error('Required parameter getCompanyRequest was null or undefined when calling get2.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/company/get`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getCompanyRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getById(getCompanyByIdRequest, observe = 'body', reportProgress = false, options) {
    if (getCompanyByIdRequest === null || getCompanyByIdRequest === undefined) {
      throw new Error('Required parameter getCompanyByIdRequest was null or undefined when calling getById.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/company/getById`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getCompanyByIdRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getEupById(getEupByIdRequest, observe = 'body', reportProgress = false, options) {
    if (getEupByIdRequest === null || getEupByIdRequest === undefined) {
      throw new Error('Required parameter getEupByIdRequest was null or undefined when calling getEupById.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/company/getEupById`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getEupByIdRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchEupForCompany(searchEupForCompanyRequest, observe = 'body', reportProgress = false, options) {
    if (searchEupForCompanyRequest === null || searchEupForCompanyRequest === undefined) {
      throw new Error('Required parameter searchEupForCompanyRequest was null or undefined when calling searchEupForCompany.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/company/eup`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchEupForCompanyRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchEupsByCompanyId(searchEupsByCompanyIdRequest, observe = 'body', reportProgress = false, options) {
    if (searchEupsByCompanyIdRequest === null || searchEupsByCompanyIdRequest === undefined) {
      throw new Error('Required parameter searchEupsByCompanyIdRequest was null or undefined when calling searchEupsByCompanyId.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/company/eupsByCompanyId`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchEupsByCompanyIdRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function CompanyControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CompanyControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CompanyControllerService,
    factory: CompanyControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CompanyControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class DictionariesService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getCompanyTypes(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/dictionary/companyTypes`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getOffices(query, page, size, sort, observe = 'body', reportProgress = false, options) {
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (query !== undefined && query !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, query, 'query');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/dictionary/offices`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  synchronize(synchronizeReportDictionaryRequest, observe = 'body', reportProgress = false, options) {
    if (synchronizeReportDictionaryRequest === null || synchronizeReportDictionaryRequest === undefined) {
      throw new Error('Required parameter synchronizeReportDictionaryRequest was null or undefined when calling synchronize.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/dictionary/synchronize`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: synchronizeReportDictionaryRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function DictionariesService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DictionariesService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DictionariesService,
    factory: DictionariesService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DictionariesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class DocumentControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getKeoDocument(getKeoDocumentRequestModel, observe = 'body', reportProgress = false, options) {
    if (getKeoDocumentRequestModel === null || getKeoDocumentRequestModel === undefined) {
      throw new Error('Required parameter getKeoDocumentRequestModel was null or undefined when calling getKeoDocument.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let localVarPath = `/document/keo`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKeoDocumentRequestModel,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKeokDocument(getKeokDocumentRequestModel, observe = 'body', reportProgress = false, options) {
    if (getKeokDocumentRequestModel === null || getKeokDocumentRequestModel === undefined) {
      throw new Error('Required parameter getKeokDocumentRequestModel was null or undefined when calling getKeokDocument.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let localVarPath = `/document/keok`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKeokDocumentRequestModel,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpoDocument1(getKpoDocumentRequestModel, observe = 'body', reportProgress = false, options) {
    if (getKpoDocumentRequestModel === null || getKpoDocumentRequestModel === undefined) {
      throw new Error('Required parameter getKpoDocumentRequestModel was null or undefined when calling getKpoDocument1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let localVarPath = `/document/kpo`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpoDocumentRequestModel,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpokDocument1(getKpokDocumentRequestModel, observe = 'body', reportProgress = false, options) {
    if (getKpokDocumentRequestModel === null || getKpokDocumentRequestModel === undefined) {
      throw new Error('Required parameter getKpokDocumentRequestModel was null or undefined when calling getKpokDocument1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let localVarPath = `/document/kpok`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpokDocumentRequestModel,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function DocumentControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DocumentControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: DocumentControllerService,
    factory: DocumentControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DocumentControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class EvidenceControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  makeEvidence(registerCardRequest, observe = 'body', reportProgress = false, options) {
    if (registerCardRequest === null || registerCardRequest === undefined) {
      throw new Error('Required parameter registerCardRequest was null or undefined when calling makeEvidence.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/evidence/registerCard`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: registerCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function EvidenceControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || EvidenceControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: EvidenceControllerService,
    factory: EvidenceControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EvidenceControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class InitialDataControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  downloadCommuneTeryt(downloadCommuneTerytRequest, observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/initial/communeteryt`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: downloadCommuneTerytRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  downloadWasteCode(requestBody, observe = 'body', reportProgress = false, options) {
    if (requestBody === null || requestBody === undefined) {
      throw new Error('Required parameter requestBody was null or undefined when calling downloadWasteCode.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (requestBody !== undefined && requestBody !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, requestBody, 'requestBody');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/initial/wastecode`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  downloadWasteProcesses(requestBody, observe = 'body', reportProgress = false, options) {
    if (requestBody === null || requestBody === undefined) {
      throw new Error('Required parameter requestBody was null or undefined when calling downloadWasteProcesses.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (requestBody !== undefined && requestBody !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, requestBody, 'requestBody');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/initial/wasteprocess`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function InitialDataControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || InitialDataControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: InitialDataControllerService,
    factory: InitialDataControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InitialDataControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class KeoControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  deleteGenerated(deleteKeoGeneratedRequestModel, observe = 'body', reportProgress = false, options) {
    if (deleteKeoGeneratedRequestModel === null || deleteKeoGeneratedRequestModel === undefined) {
      throw new Error('Required parameter deleteKeoGeneratedRequestModel was null or undefined when calling deleteGenerated.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/keo/delete`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: deleteKeoGeneratedRequestModel,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getDetails(getKeoDetailsRequest, observe = 'body', reportProgress = false, options) {
    if (getKeoDetailsRequest === null || getKeoDetailsRequest === undefined) {
      throw new Error('Required parameter getKeoDetailsRequest was null or undefined when calling getDetails.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/keo/details`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKeoDetailsRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getGenerated(getKeoGeneratedRequest, observe = 'body', reportProgress = false, options) {
    if (getKeoGeneratedRequest === null || getKeoGeneratedRequest === undefined) {
      throw new Error('Required parameter getKeoGeneratedRequest was null or undefined when calling getGenerated.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/keo/generated`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKeoGeneratedRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function KeoControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || KeoControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: KeoControllerService,
    factory: KeoControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KeoControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class KeokControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  getTransferredDetails(getKeokDetailsRequest, observe = 'body', reportProgress = false, options) {
    if (getKeokDetailsRequest === null || getKeokDetailsRequest === undefined) {
      throw new Error('Required parameter getKeokDetailsRequest was null or undefined when calling getTransferredDetails.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/keok/details`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKeokDetailsRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function KeokControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || KeokControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: KeokControllerService,
    factory: KeokControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KeokControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class KpoControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  approve1(kpoApproveRequest, observe = 'body', reportProgress = false, options) {
    if (kpoApproveRequest === null || kpoApproveRequest === undefined) {
      throw new Error('Required parameter kpoApproveRequest was null or undefined when calling approve1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/approve`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoApproveRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  approveAndGenerateAsSender1(kpoApproveAndGenerateAsSenderRequest, observe = 'body', reportProgress = false, options) {
    if (kpoApproveAndGenerateAsSenderRequest === null || kpoApproveAndGenerateAsSenderRequest === undefined) {
      throw new Error('Required parameter kpoApproveAndGenerateAsSenderRequest was null or undefined when calling approveAndGenerateAsSender1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/approveAndGenerateAsSender`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoApproveAndGenerateAsSenderRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  changeToReceiveConfirmation1(kpoReceiveConfirmationRequest, observe = 'body', reportProgress = false, options) {
    if (kpoReceiveConfirmationRequest === null || kpoReceiveConfirmationRequest === undefined) {
      throw new Error('Required parameter kpoReceiveConfirmationRequest was null or undefined when calling changeToReceiveConfirmation1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/receiveConfirmation`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoReceiveConfirmationRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  changeToReject(kpoRejectRequest, observe = 'body', reportProgress = false, options) {
    if (kpoRejectRequest === null || kpoRejectRequest === undefined) {
      throw new Error('Required parameter kpoRejectRequest was null or undefined when calling changeToReject.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/reject`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoRejectRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  confirmationTransport1(kpoConfirmationTransportRequest, observe = 'body', reportProgress = false, options) {
    if (kpoConfirmationTransportRequest === null || kpoConfirmationTransportRequest === undefined) {
      throw new Error('Required parameter kpoConfirmationTransportRequest was null or undefined when calling confirmationTransport1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/confirmationTransport`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoConfirmationTransportRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  copyKpoCard(copyCardRequest, observe = 'body', reportProgress = false, options) {
    if (copyCardRequest === null || copyCardRequest === undefined) {
      throw new Error('Required parameter copyCardRequest was null or undefined when calling copyKpoCard.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/copy`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: copyCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  createPlannedCard(createKpoPlannedCardRequest, observe = 'body', reportProgress = false, options) {
    if (createKpoPlannedCardRequest === null || createKpoPlannedCardRequest === undefined) {
      throw new Error('Required parameter createKpoPlannedCardRequest was null or undefined when calling createPlannedCard.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/create`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createKpoPlannedCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deletePlanned1(kpoDeleteRequest, observe = 'body', reportProgress = false, options) {
    if (kpoDeleteRequest === null || kpoDeleteRequest === undefined) {
      throw new Error('Required parameter kpoDeleteRequest was null or undefined when calling deletePlanned1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/delete`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoDeleteRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  downloadCardDetails1(kpoDetailsRequest, observe = 'body', reportProgress = false, options) {
    if (kpoDetailsRequest === null || kpoDetailsRequest === undefined) {
      throw new Error('Required parameter kpoDetailsRequest was null or undefined when calling downloadCardDetails1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/details`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoDetailsRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  editApprovedCard(editKpoApprovedCardRequest, observe = 'body', reportProgress = false, options) {
    if (editKpoApprovedCardRequest === null || editKpoApprovedCardRequest === undefined) {
      throw new Error('Required parameter editKpoApprovedCardRequest was null or undefined when calling editApprovedCard.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/editApprovedCard`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: editKpoApprovedCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  editPlannedCard(editKpoPlannedCardRequest, observe = 'body', reportProgress = false, options) {
    if (editKpoPlannedCardRequest === null || editKpoPlannedCardRequest === undefined) {
      throw new Error('Required parameter editKpoPlannedCardRequest was null or undefined when calling editPlannedCard.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/editPlannedCard`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: editKpoPlannedCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  generateConfirmationByCarrier(kpoGenerateConfirmationByCarrierRequest, observe = 'body', reportProgress = false, options) {
    if (kpoGenerateConfirmationByCarrierRequest === null || kpoGenerateConfirmationByCarrierRequest === undefined) {
      throw new Error('Required parameter kpoGenerateConfirmationByCarrierRequest was null or undefined when calling generateConfirmationByCarrier.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/generateConfirmationByCarrier`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoGenerateConfirmationByCarrierRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  generateConfirmationBySender(kpoGenerateConfirmationBySenderRequest, observe = 'body', reportProgress = false, options) {
    if (kpoGenerateConfirmationBySenderRequest === null || kpoGenerateConfirmationBySenderRequest === undefined) {
      throw new Error('Required parameter kpoGenerateConfirmationBySenderRequest was null or undefined when calling generateConfirmationBySender.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/generateConfirmationBySender`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoGenerateConfirmationBySenderRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpoDocument(getKpoDocumentRequest, observe = 'body', reportProgress = false, options) {
    if (getKpoDocumentRequest === null || getKpoDocumentRequest === undefined) {
      throw new Error('Required parameter getKpoDocumentRequest was null or undefined when calling getKpoDocument.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let localVarPath = `/kpo/document`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpoDocumentRequest,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpoPdf(getKpoDocumentRequest, observe = 'body', reportProgress = false, options) {
    if (getKpoDocumentRequest === null || getKpoDocumentRequest === undefined) {
      throw new Error('Required parameter getKpoDocumentRequest was null or undefined when calling getKpoPdf.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let localVarPath = `/kpo/pdf`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpoDocumentRequest,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpoPrintingData(getKpoPrintingDataRequest, observe = 'body', reportProgress = false, options) {
    if (getKpoPrintingDataRequest === null || getKpoPrintingDataRequest === undefined) {
      throw new Error('Required parameter getKpoPrintingDataRequest was null or undefined when calling getKpoPrintingData.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/printingData`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpoPrintingDataRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  revise1(kpoReviseRequest, observe = 'body', reportProgress = false, options) {
    if (kpoReviseRequest === null || kpoReviseRequest === undefined) {
      throw new Error('Required parameter kpoReviseRequest was null or undefined when calling revise1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/revise`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoReviseRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  withdrawn1(kpoWithdrawnRequest, observe = 'body', reportProgress = false, options) {
    if (kpoWithdrawnRequest === null || kpoWithdrawnRequest === undefined) {
      throw new Error('Required parameter kpoWithdrawnRequest was null or undefined when calling withdrawn1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpo/withdrawn`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpoWithdrawnRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function KpoControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || KpoControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: KpoControllerService,
    factory: KpoControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KpoControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class KpokControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  approve(kpokApproveRequest, observe = 'body', reportProgress = false, options) {
    if (kpokApproveRequest === null || kpokApproveRequest === undefined) {
      throw new Error('Required parameter kpokApproveRequest was null or undefined when calling approve.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/approve`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokApproveRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  approveAndGenerateAsSender(kpokApproveAndGenerateAsSenderRequest, observe = 'body', reportProgress = false, options) {
    if (kpokApproveAndGenerateAsSenderRequest === null || kpokApproveAndGenerateAsSenderRequest === undefined) {
      throw new Error('Required parameter kpokApproveAndGenerateAsSenderRequest was null or undefined when calling approveAndGenerateAsSender.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/approveAndGenerateAsSender`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokApproveAndGenerateAsSenderRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  changeToReceiveConfirmation(kpokReceiveConfirmationRequest, observe = 'body', reportProgress = false, options) {
    if (kpokReceiveConfirmationRequest === null || kpokReceiveConfirmationRequest === undefined) {
      throw new Error('Required parameter kpokReceiveConfirmationRequest was null or undefined when calling changeToReceiveConfirmation.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/receiveConfirmation`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokReceiveConfirmationRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  confirmationTransport(kpokConfirmationTransportRequest, observe = 'body', reportProgress = false, options) {
    if (kpokConfirmationTransportRequest === null || kpokConfirmationTransportRequest === undefined) {
      throw new Error('Required parameter kpokConfirmationTransportRequest was null or undefined when calling confirmationTransport.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/confirmTransport`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokConfirmationTransportRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  copyReceiveKpokCard(copyCardRequest, observe = 'body', reportProgress = false, options) {
    if (copyCardRequest === null || copyCardRequest === undefined) {
      throw new Error('Required parameter copyCardRequest was null or undefined when calling copyReceiveKpokCard.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/copy/receive`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: copyCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  copyTransferKpokCard(copyCardRequest, observe = 'body', reportProgress = false, options) {
    if (copyCardRequest === null || copyCardRequest === undefined) {
      throw new Error('Required parameter copyCardRequest was null or undefined when calling copyTransferKpokCard.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/copy/transfer`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: copyCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  createPlannedCardForReceive(createKpokPlannedCardForReceiveRequest, observe = 'body', reportProgress = false, options) {
    if (createKpokPlannedCardForReceiveRequest === null || createKpokPlannedCardForReceiveRequest === undefined) {
      throw new Error('Required parameter createKpokPlannedCardForReceiveRequest was null or undefined when calling createPlannedCardForReceive.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/createForReceive`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createKpokPlannedCardForReceiveRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  createPlannedCardForTransfer(createKpokPlannedCardForTransferRequest, observe = 'body', reportProgress = false, options) {
    if (createKpokPlannedCardForTransferRequest === null || createKpokPlannedCardForTransferRequest === undefined) {
      throw new Error('Required parameter createKpokPlannedCardForTransferRequest was null or undefined when calling createPlannedCardForTransfer.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/createForTransfer`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createKpokPlannedCardForTransferRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  deletePlanned(kpokDeleteRequest, observe = 'body', reportProgress = false, options) {
    if (kpokDeleteRequest === null || kpokDeleteRequest === undefined) {
      throw new Error('Required parameter kpokDeleteRequest was null or undefined when calling deletePlanned.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/delete`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokDeleteRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  downloadCardDetails(kpokDetailsRequest, observe = 'body', reportProgress = false, options) {
    if (kpokDetailsRequest === null || kpokDetailsRequest === undefined) {
      throw new Error('Required parameter kpokDetailsRequest was null or undefined when calling downloadCardDetails.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/details`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokDetailsRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  editApprovedCardForReceive(editKpokApprovedCardForReceiveRequest, observe = 'body', reportProgress = false, options) {
    if (editKpokApprovedCardForReceiveRequest === null || editKpokApprovedCardForReceiveRequest === undefined) {
      throw new Error('Required parameter editKpokApprovedCardForReceiveRequest was null or undefined when calling editApprovedCardForReceive.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/editApprovedCardForReceive`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: editKpokApprovedCardForReceiveRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  editApprovedCardForTransfer(editKpokApprovedCardForTransferRequest, observe = 'body', reportProgress = false, options) {
    if (editKpokApprovedCardForTransferRequest === null || editKpokApprovedCardForTransferRequest === undefined) {
      throw new Error('Required parameter editKpokApprovedCardForTransferRequest was null or undefined when calling editApprovedCardForTransfer.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/editApprovedCardForTransfer`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: editKpokApprovedCardForTransferRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  editPlannedCardForReceive(editKpokPlannedCardForReceiveRequest, observe = 'body', reportProgress = false, options) {
    if (editKpokPlannedCardForReceiveRequest === null || editKpokPlannedCardForReceiveRequest === undefined) {
      throw new Error('Required parameter editKpokPlannedCardForReceiveRequest was null or undefined when calling editPlannedCardForReceive.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/editForReceive`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: editKpokPlannedCardForReceiveRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  editPlannedCardForTransfer(editKpokPlannedCardForTransferRequest, observe = 'body', reportProgress = false, options) {
    if (editKpokPlannedCardForTransferRequest === null || editKpokPlannedCardForTransferRequest === undefined) {
      throw new Error('Required parameter editKpokPlannedCardForTransferRequest was null or undefined when calling editPlannedCardForTransfer.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/editForTransfer`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: editKpokPlannedCardForTransferRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  generateConfirmationAsCarrier(kpokGenerateConfirmationAsCarrierRequest, observe = 'body', reportProgress = false, options) {
    if (kpokGenerateConfirmationAsCarrierRequest === null || kpokGenerateConfirmationAsCarrierRequest === undefined) {
      throw new Error('Required parameter kpokGenerateConfirmationAsCarrierRequest was null or undefined when calling generateConfirmationAsCarrier.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/generateConfirmationByCarrier`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokGenerateConfirmationAsCarrierRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  generateConfirmationAsSender(kpokGenerateConfirmationRequest, observe = 'body', reportProgress = false, options) {
    if (kpokGenerateConfirmationRequest === null || kpokGenerateConfirmationRequest === undefined) {
      throw new Error('Required parameter kpokGenerateConfirmationRequest was null or undefined when calling generateConfirmationAsSender.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/generateConfirmationBySender`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokGenerateConfirmationRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpokDocument(getKpokDocumentRequest, observe = 'body', reportProgress = false, options) {
    if (getKpokDocumentRequest === null || getKpokDocumentRequest === undefined) {
      throw new Error('Required parameter getKpokDocumentRequest was null or undefined when calling getKpokDocument.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let localVarPath = `/kpok/document`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpokDocumentRequest,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpokPdf(getKpokPdfRequest, observe = 'body', reportProgress = false, options) {
    if (getKpokPdfRequest === null || getKpokPdfRequest === undefined) {
      throw new Error('Required parameter getKpokPdfRequest was null or undefined when calling getKpokPdf.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let localVarPath = `/kpok/pdf`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpokPdfRequest,
      responseType: "blob",
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpokReceivePrintingData(getKpokPrintingDataRequest, observe = 'body', reportProgress = false, options) {
    if (getKpokPrintingDataRequest === null || getKpokPrintingDataRequest === undefined) {
      throw new Error('Required parameter getKpokPrintingDataRequest was null or undefined when calling getKpokReceivePrintingData.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/getReceivePrintingData`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpokPrintingDataRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getKpokTransferPrintingData(getKpokPrintingDataRequest, observe = 'body', reportProgress = false, options) {
    if (getKpokPrintingDataRequest === null || getKpokPrintingDataRequest === undefined) {
      throw new Error('Required parameter getKpokPrintingDataRequest was null or undefined when calling getKpokTransferPrintingData.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/getTransferPrintingData`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getKpokPrintingDataRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  reject(kpokRejectRequest, observe = 'body', reportProgress = false, options) {
    if (kpokRejectRequest === null || kpokRejectRequest === undefined) {
      throw new Error('Required parameter kpokRejectRequest was null or undefined when calling reject.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/reject`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokRejectRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  revise(kpokReviseRequest, observe = 'body', reportProgress = false, options) {
    if (kpokReviseRequest === null || kpokReviseRequest === undefined) {
      throw new Error('Required parameter kpokReviseRequest was null or undefined when calling revise.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/revise`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokReviseRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  withdrawn(kpokWithdrawnRequest, observe = 'body', reportProgress = false, options) {
    if (kpokWithdrawnRequest === null || kpokWithdrawnRequest === undefined) {
      throw new Error('Required parameter kpokWithdrawnRequest was null or undefined when calling withdrawn.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/kpok/withdrawn`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: kpokWithdrawnRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function KpokControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || KpokControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: KpokControllerService,
    factory: KpokControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(KpokControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ManufacturingReportService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  canConsumeForm(consumes) {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  calculate(companyId, reportId, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling calculate.');
    }
    if (reportId === null || reportId === undefined) {
      throw new Error('Required parameter reportId was null or undefined when calling calculate.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (reportId !== undefined && reportId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, reportId, 'reportId');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/manufacturingReport/calculate`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  details1(companyId, reportId, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling details1.');
    }
    if (reportId === null || reportId === undefined) {
      throw new Error('Required parameter reportId was null or undefined when calling details1.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (reportId !== undefined && reportId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, reportId, 'reportId');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/manufacturingReport/details`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  get7(companyId, year, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling get7.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling get7.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/manufacturingReport/getList`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  save1(manufacturingFillTablesDataRequest, observe = 'body', reportProgress = false, options) {
    if (manufacturingFillTablesDataRequest === null || manufacturingFillTablesDataRequest === undefined) {
      throw new Error('Required parameter manufacturingFillTablesDataRequest was null or undefined when calling save1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/manufacturingReport/save`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: manufacturingFillTablesDataRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  saveManual(saveManualRequest, observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/manufacturingReport/save/manual`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: saveManualRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  verifyProductReports1(verifyManufacturingReportsRequest, observe = 'body', reportProgress = false, options) {
    if (verifyManufacturingReportsRequest === null || verifyManufacturingReportsRequest === undefined) {
      throw new Error('Required parameter verifyManufacturingReportsRequest was null or undefined when calling verifyProductReports1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/manufacturingReport/verify`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: verifyManufacturingReportsRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  verifyProductReportsByFile1(year, file, observe = 'body', reportProgress = false, options) {
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling verifyProductReportsByFile1.');
    }
    if (file === null || file === undefined) {
      throw new Error('Required parameter file was null or undefined when calling verifyProductReportsByFile1.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['multipart/form-data'];
    const canConsumeForm = this.canConsumeForm(consumes);
    let localVarFormParams;
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({
        encoder: this.encoder
      });
    }
    if (file !== undefined) {
      localVarFormParams = localVarFormParams.append('file', file) || localVarFormParams;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/manufacturingReport/verify/file`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ManufacturingReportService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ManufacturingReportService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ManufacturingReportService,
    factory: ManufacturingReportService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ManufacturingReportService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class ProductReportService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  canConsumeForm(consumes) {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  calculate1(companyId, year, voivodeship, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling calculate1.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling calculate1.');
    }
    if (voivodeship === null || voivodeship === undefined) {
      throw new Error('Required parameter voivodeship was null or undefined when calling calculate1.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (voivodeship !== undefined && voivodeship !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, voivodeship, 'voivodeship');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/calculate`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  create(createReportsRequest, observe = 'body', reportProgress = false, options) {
    if (createReportsRequest === null || createReportsRequest === undefined) {
      throw new Error('Required parameter createReportsRequest was null or undefined when calling create.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/create`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createReportsRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  createCorrectionReport(createReportCorrectionRequest, observe = 'body', reportProgress = false, options) {
    if (createReportCorrectionRequest === null || createReportCorrectionRequest === undefined) {
      throw new Error('Required parameter createReportCorrectionRequest was null or undefined when calling createCorrectionReport.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/correct`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: createReportCorrectionRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  details(companyId, reportId, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling details.');
    }
    if (reportId === null || reportId === undefined) {
      throw new Error('Required parameter reportId was null or undefined when calling details.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (reportId !== undefined && reportId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, reportId, 'reportId');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/details`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  get6(companyId, year, page, size, sort, observe = 'body', reportProgress = false, options) {
    if (companyId === null || companyId === undefined) {
      throw new Error('Required parameter companyId was null or undefined when calling get6.');
    }
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling get6.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (companyId !== undefined && companyId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, companyId, 'companyId');
    }
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    if (page !== undefined && page !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, page, 'page');
    }
    if (size !== undefined && size !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, size, 'size');
    }
    if (sort) {
      sort.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, element, 'sort');
      });
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/getList`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  save(fillTablesDataRequest, observe = 'body', reportProgress = false, options) {
    if (fillTablesDataRequest === null || fillTablesDataRequest === undefined) {
      throw new Error('Required parameter fillTablesDataRequest was null or undefined when calling save.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/save`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: fillTablesDataRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  sendReport(sendReportRequest, observe = 'body', reportProgress = false, options) {
    if (sendReportRequest === null || sendReportRequest === undefined) {
      throw new Error('Required parameter sendReportRequest was null or undefined when calling sendReport.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: sendReportRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  verifyProductReports(verifyProductReportsRequest, observe = 'body', reportProgress = false, options) {
    if (verifyProductReportsRequest === null || verifyProductReportsRequest === undefined) {
      throw new Error('Required parameter verifyProductReportsRequest was null or undefined when calling verifyProductReports.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/verify`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: verifyProductReportsRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  verifyProductReportsByFile(year, file, observe = 'body', reportProgress = false, options) {
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling verifyProductReportsByFile.');
    }
    if (file === null || file === undefined) {
      throw new Error('Required parameter file was null or undefined when calling verifyProductReportsByFile.');
    }
    let localVarQueryParameters = new HttpParams({
      encoder: this.encoder
    });
    if (year !== undefined && year !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, year, 'year');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['multipart/form-data'];
    const canConsumeForm = this.canConsumeForm(consumes);
    let localVarFormParams;
    let localVarUseForm = false;
    let localVarConvertFormParamsToString = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
    localVarUseForm = canConsumeForm;
    if (localVarUseForm) {
      localVarFormParams = new FormData();
    } else {
      localVarFormParams = new HttpParams({
        encoder: this.encoder
      });
    }
    if (file !== undefined) {
      localVarFormParams = localVarFormParams.append('file', file) || localVarFormParams;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/verify/file`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
      params: localVarQueryParameters,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  voivodeshipCheck(checkReportsRequest, observe = 'body', reportProgress = false, options) {
    if (checkReportsRequest === null || checkReportsRequest === undefined) {
      throw new Error('Required parameter checkReportsRequest was null or undefined when calling voivodeshipCheck.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/productReport/voivodeship/check`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: checkReportsRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function ProductReportService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ProductReportService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: ProductReportService,
    factory: ProductReportService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProductReportService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class SearchControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  searchKeo(searchKeoRequestModel, observe = 'body', reportProgress = false, options) {
    if (searchKeoRequestModel === null || searchKeoRequestModel === undefined) {
      throw new Error('Required parameter searchKeoRequestModel was null or undefined when calling searchKeo.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/keo`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchKeoRequestModel,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchKeok(searchKeokRequestModel, observe = 'body', reportProgress = false, options) {
    if (searchKeokRequestModel === null || searchKeokRequestModel === undefined) {
      throw new Error('Required parameter searchKeokRequestModel was null or undefined when calling searchKeok.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/keok`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchKeokRequestModel,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchKpoAsCarrier(searchCardRequest, observe = 'body', reportProgress = false, options) {
    if (searchCardRequest === null || searchCardRequest === undefined) {
      throw new Error('Required parameter searchCardRequest was null or undefined when calling searchKpoAsCarrier.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpo/searchAsCarrier`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchKpoAsReceiver(searchCardRequest, observe = 'body', reportProgress = false, options) {
    if (searchCardRequest === null || searchCardRequest === undefined) {
      throw new Error('Required parameter searchCardRequest was null or undefined when calling searchKpoAsReceiver.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpo/searchAsReceiver`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchKpoAsSender(searchCardRequest, observe = 'body', reportProgress = false, options) {
    if (searchCardRequest === null || searchCardRequest === undefined) {
      throw new Error('Required parameter searchCardRequest was null or undefined when calling searchKpoAsSender.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpo/searchAsSender`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchKpokAsCarrier(searchCardRequest, observe = 'body', reportProgress = false, options) {
    if (searchCardRequest === null || searchCardRequest === undefined) {
      throw new Error('Required parameter searchCardRequest was null or undefined when calling searchKpokAsCarrier.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpok/searchAsCarrier`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchKpokAsReceiver(searchCardRequest, observe = 'body', reportProgress = false, options) {
    if (searchCardRequest === null || searchCardRequest === undefined) {
      throw new Error('Required parameter searchCardRequest was null or undefined when calling searchKpokAsReceiver.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpok/searchAsReceiver`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchKpokAsSender(searchCardRequest, observe = 'body', reportProgress = false, options) {
    if (searchCardRequest === null || searchCardRequest === undefined) {
      throw new Error('Required parameter searchCardRequest was null or undefined when calling searchKpokAsSender.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpok/searchAsSender`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchCardRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchReceiveKpokAsSender(searchReceiverKpokCardsAsSenderRequestModel, observe = 'body', reportProgress = false, options) {
    if (searchReceiverKpokCardsAsSenderRequestModel === null || searchReceiverKpokCardsAsSenderRequestModel === undefined) {
      throw new Error('Required parameter searchReceiverKpokCardsAsSenderRequestModel was null or undefined when calling searchReceiveKpokAsSender.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpok/receiveCardsAsSender`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchReceiverKpokCardsAsSenderRequestModel,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchReceiveKposAsSender(searchReceiverKposCardsAsSenderRequestModel, observe = 'body', reportProgress = false, options) {
    if (searchReceiverKposCardsAsSenderRequestModel === null || searchReceiverKposCardsAsSenderRequestModel === undefined) {
      throw new Error('Required parameter searchReceiverKposCardsAsSenderRequestModel was null or undefined when calling searchReceiveKposAsSender.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpo/receiveCardsAsSender`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchReceiverKposCardsAsSenderRequestModel,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchSenderKpokAsReceiver(searchSenderKpokCardsAsReceiverRequestModel, observe = 'body', reportProgress = false, options) {
    if (searchSenderKpokCardsAsReceiverRequestModel === null || searchSenderKpokCardsAsReceiverRequestModel === undefined) {
      throw new Error('Required parameter searchSenderKpokCardsAsReceiverRequestModel was null or undefined when calling searchSenderKpokAsReceiver.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpok/senderCardsAsReceiver`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchSenderKpokCardsAsReceiverRequestModel,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  searchSenderKposAsReceiver(searchSenderKposCardsAsReceiverRequestModel, observe = 'body', reportProgress = false, options) {
    if (searchSenderKposCardsAsReceiverRequestModel === null || searchSenderKposCardsAsReceiverRequestModel === undefined) {
      throw new Error('Required parameter searchSenderKposCardsAsReceiverRequestModel was null or undefined when calling searchSenderKposAsReceiver.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/search/kpo/senderCardsAsReceiver`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: searchSenderKposCardsAsReceiverRequestModel,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function SearchControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SearchControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: SearchControllerService,
    factory: SearchControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SearchControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class WasteCodeControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  get1(getWasteCodeRequest, observe = 'body', reportProgress = false, options) {
    if (getWasteCodeRequest === null || getWasteCodeRequest === undefined) {
      throw new Error('Required parameter getWasteCodeRequest was null or undefined when calling get1.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/wastecode/get`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getWasteCodeRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  get5(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling get5.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/wastecode/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getAll1(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/wastecode/getAll`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function WasteCodeControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WasteCodeControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WasteCodeControllerService,
    factory: WasteCodeControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WasteCodeControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */
class WasteProcessControllerService {
  httpClient;
  basePath = 'http://wasterecord-service:8083/wasterecord';
  defaultHeaders = new HttpHeaders();
  configuration = new Configuration();
  encoder;
  constructor(httpClient, basePath, configuration) {
    this.httpClient = httpClient;
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      if (Array.isArray(basePath) && basePath.length > 0) {
        basePath = basePath[0];
      }
      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }
  // @ts-ignore
  addToHttpParams(httpParams, value, key) {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }
  addToHttpParamsRecursive(httpParams, value, key) {
    if (value == null) {
      return httpParams;
    }
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        value.forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, value.toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }
  get(getWasteProcessRequest, observe = 'body', reportProgress = false, options) {
    if (getWasteProcessRequest === null || getWasteProcessRequest === undefined) {
      throw new Error('Required parameter getWasteProcessRequest was null or undefined when calling get.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    // to determine the Content-Type header
    const consumes = ['application/json'];
    const httpContentTypeSelected = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/wasteprocess/get`;
    return this.httpClient.request('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: getWasteProcessRequest,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  get4(id, observe = 'body', reportProgress = false, options) {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling get4.');
    }
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/wasteprocess/${this.configuration.encodeParam({
      name: "id",
      value: id,
      in: "path",
      style: "simple",
      explode: false,
      dataType: "number",
      dataFormat: "int32"
    })}`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  getAll(observe = 'body', reportProgress = false, options) {
    let localVarHeaders = this.defaultHeaders;
    let localVarCredential;
    // authentication (Bearer JWT Token) required
    localVarCredential = this.configuration.lookupCredential('Bearer JWT Token');
    if (localVarCredential) {
      localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
    }
    let localVarHttpHeaderAcceptSelected = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts = ['application/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }
    let localVarHttpContext = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }
    let localVarTransferCache = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }
    let responseType_ = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }
    let localVarPath = `/wasteprocess/getAll`;
    return this.httpClient.request('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
  static ɵfac = function WasteProcessControllerService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || WasteProcessControllerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(BASE_PATH, 8), i0.ɵɵinject(Configuration, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: WasteProcessControllerService,
    factory: WasteProcessControllerService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WasteProcessControllerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [BASE_PATH]
    }]
  }, {
    type: Configuration,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
const APIS = [CommuneControllerService, CompanyControllerService, DictionariesService, DocumentControllerService, EvidenceControllerService, InitialDataControllerService, KeoControllerService, KeokControllerService, KpoControllerService, KpokControllerService, ManufacturingReportService, ProductReportService, SearchControllerService, WasteCodeControllerService, WasteProcessControllerService];

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var AdditionalReportInfo;
(function (AdditionalReportInfo) {
  AdditionalReportInfo.VoivodeshipEnum = {
    Dolnoslaskie: 'DOLNOSLASKIE',
    KujawskoPomorskie: 'KUJAWSKO_POMORSKIE',
    Lubelskie: 'LUBELSKIE',
    Lubuskie: 'LUBUSKIE',
    Lodzkie: 'LODZKIE',
    Malopolskie: 'MALOPOLSKIE',
    Mazowieckie: 'MAZOWIECKIE',
    Opolskie: 'OPOLSKIE',
    Podkarpackie: 'PODKARPACKIE',
    Podlaskie: 'PODLASKIE',
    Pomorskie: 'POMORSKIE',
    Slaskie: 'SLASKIE',
    Swietokrzyskie: 'SWIETOKRZYSKIE',
    WarmiskoMazurskie: 'WARMIŃSKO_MAZURSKIE',
    Wielkopolskie: 'WIELKOPOLSKIE',
    Zachodniopomorskie: 'ZACHODNIOPOMORSKIE'
  };
})(AdditionalReportInfo || (AdditionalReportInfo = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var CardsSearchParameters;
(function (CardsSearchParameters) {
  CardsSearchParameters.StatusListEnum = {
    Planned: 'PLANNED',
    Approved: 'APPROVED',
    Withdrawn: 'WITHDRAWN',
    TransportConfirmation: 'TRANSPORT_CONFIRMATION',
    ConfirmationGenerated: 'CONFIRMATION_GENERATED',
    Rejected: 'REJECTED',
    ReceiveConfirmation: 'RECEIVE_CONFIRMATION',
    Undefined: 'UNDEFINED'
  };
  CardsSearchParameters.CardSubTypeEnum = {
    Transfer: 'TRANSFER',
    Receive: 'RECEIVE'
  };
})(CardsSearchParameters || (CardsSearchParameters = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var ChangeStatusResult;
(function (ChangeStatusResult) {
  ChangeStatusResult.StatusEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
  };
})(ChangeStatusResult || (ChangeStatusResult = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var CommuneTeryt;
(function (CommuneTeryt) {
  CommuneTeryt.AreaTypeEnum = {
    Voivodeship: 'VOIVODESHIP',
    District: 'DISTRICT',
    Commune: 'COMMUNE'
  };
})(CommuneTeryt || (CommuneTeryt = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var CompanyItemResult;
(function (CompanyItemResult) {
  CompanyItemResult.StatusEnum = {
    Success: 'SUCCESS',
    NoData: 'NO_DATA',
    NotAllSent: 'NOT_ALL_SENT',
    Error: 'ERROR'
  };
})(CompanyItemResult || (CompanyItemResult = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var DeleteGeneratedResponse;
(function (DeleteGeneratedResponse) {
  DeleteGeneratedResponse.StatusEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
  };
})(DeleteGeneratedResponse || (DeleteGeneratedResponse = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var EvidenceEntryResult;
(function (EvidenceEntryResult) {
  EvidenceEntryResult.EvidenceTypeEnum = {
    Keo: 'KEO',
    Keok: 'KEOK'
  };
  EvidenceEntryResult.SectionEnum = {
    Receive: 'RECEIVE',
    Transferred: 'TRANSFERRED',
    Collected: 'COLLECTED',
    Generated: 'GENERATED'
  };
  EvidenceEntryResult.EntryStatusEnum = {
    Ok: 'OK',
    Failed: 'FAILED',
    Rollbacked: 'ROLLBACKED'
  };
})(EvidenceEntryResult || (EvidenceEntryResult = {}));
var EvidenceItem;
(function (EvidenceItem) {
  EvidenceItem.TypeEnum = {
    Keo: 'KEO',
    Keok: 'KEOK'
  };
})(EvidenceItem || (EvidenceItem = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var GenerateNewReportResult;
(function (GenerateNewReportResult) {
  GenerateNewReportResult.StatusEnum = {
    Success: 'SUCCESS',
    Error: 'ERROR'
  };
})(GenerateNewReportResult || (GenerateNewReportResult = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var GetCommunesRequest;
(function (GetCommunesRequest) {
  GetCommunesRequest.AreaTypeEnum = {
    Voivodeship: 'VOIVODESHIP',
    District: 'DISTRICT',
    Commune: 'COMMUNE'
  };
})(GetCommunesRequest || (GetCommunesRequest = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var GetKpokDocumentRequestModel;
(function (GetKpokDocumentRequestModel) {
  GetKpokDocumentRequestModel.CompanyRoleEnum = {
    Sender: 'SENDER',
    Receiver: 'RECEIVER'
  };
})(GetKpokDocumentRequestModel || (GetKpokDocumentRequestModel = {}));
var GetKpokPdfRequest;
(function (GetKpokPdfRequest) {
  GetKpokPdfRequest.CardSubTypeEnum = {
    Transfer: 'TRANSFER',
    Receive: 'RECEIVE'
  };
})(GetKpokPdfRequest || (GetKpokPdfRequest = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var KpoDetailsRequest;
(function (KpoDetailsRequest) {
  KpoDetailsRequest.StatusEnum = {
    Planned: 'PLANNED',
    Approved: 'APPROVED',
    Withdrawn: 'WITHDRAWN',
    TransportConfirmation: 'TRANSPORT_CONFIRMATION',
    ConfirmationGenerated: 'CONFIRMATION_GENERATED',
    Rejected: 'REJECTED',
    ReceiveConfirmation: 'RECEIVE_CONFIRMATION',
    Undefined: 'UNDEFINED'
  };
  KpoDetailsRequest.CompanyRoleEnum = {
    Sender: 'SENDER',
    Carrier: 'CARRIER',
    Receiver: 'RECEIVER'
  };
})(KpoDetailsRequest || (KpoDetailsRequest = {}));
var KpokDetailsRequest;
(function (KpokDetailsRequest) {
  KpokDetailsRequest.StatusEnum = {
    Planned: 'PLANNED',
    Approved: 'APPROVED',
    Withdrawn: 'WITHDRAWN',
    TransportConfirmation: 'TRANSPORT_CONFIRMATION',
    ConfirmationGenerated: 'CONFIRMATION_GENERATED',
    Rejected: 'REJECTED',
    ReceiveConfirmation: 'RECEIVE_CONFIRMATION',
    Undefined: 'UNDEFINED'
  };
  KpokDetailsRequest.CompanyRoleEnum = {
    Sender: 'SENDER',
    Carrier: 'CARRIER',
    Receiver: 'RECEIVER'
  };
})(KpokDetailsRequest || (KpokDetailsRequest = {}));
var ManufacturingCompanyUnit;
(function (ManufacturingCompanyUnit) {
  ManufacturingCompanyUnit.VoivodeshipNameEnum = {
    Dolnoslaskie: 'DOLNOSLASKIE',
    KujawskoPomorskie: 'KUJAWSKO_POMORSKIE',
    Lubelskie: 'LUBELSKIE',
    Lubuskie: 'LUBUSKIE',
    Lodzkie: 'LODZKIE',
    Malopolskie: 'MALOPOLSKIE',
    Mazowieckie: 'MAZOWIECKIE',
    Opolskie: 'OPOLSKIE',
    Podkarpackie: 'PODKARPACKIE',
    Podlaskie: 'PODLASKIE',
    Pomorskie: 'POMORSKIE',
    Slaskie: 'SLASKIE',
    Swietokrzyskie: 'SWIETOKRZYSKIE',
    WarmiskoMazurskie: 'WARMIŃSKO_MAZURSKIE',
    Wielkopolskie: 'WIELKOPOLSKIE',
    Zachodniopomorskie: 'ZACHODNIOPOMORSKIE'
  };
})(ManufacturingCompanyUnit || (ManufacturingCompanyUnit = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var ManufacturingSaveEupForSection1ResultItem;
(function (ManufacturingSaveEupForSection1ResultItem) {
  ManufacturingSaveEupForSection1ResultItem.StatusEnum = {
    Success: 'SUCCESS',
    Skipped: 'SKIPPED',
    Failure: 'FAILURE',
    NotExecuted: 'NOT_EXECUTED'
  };
})(ManufacturingSaveEupForSection1ResultItem || (ManufacturingSaveEupForSection1ResultItem = {}));
var ManufacturingSaveTablesDataInfoDto;
(function (ManufacturingSaveTablesDataInfoDto) {
  ManufacturingSaveTablesDataInfoDto.StatusEnum = {
    Success: 'SUCCESS',
    Failed: 'FAILED'
  };
})(ManufacturingSaveTablesDataInfoDto || (ManufacturingSaveTablesDataInfoDto = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var ManufacturingWasteDto;
(function (ManufacturingWasteDto) {
  ManufacturingWasteDto.SavedStatusEnum = {
    Success: 'SUCCESS',
    Skipped: 'SKIPPED',
    Failure: 'FAILURE',
    NotExecuted: 'NOT_EXECUTED'
  };
})(ManufacturingWasteDto || (ManufacturingWasteDto = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var RegisterCardRequest;
(function (RegisterCardRequest) {
  RegisterCardRequest.CardStatusEnum = {
    Planned: 'PLANNED',
    Approved: 'APPROVED',
    Withdrawn: 'WITHDRAWN',
    TransportConfirmation: 'TRANSPORT_CONFIRMATION',
    ConfirmationGenerated: 'CONFIRMATION_GENERATED',
    Rejected: 'REJECTED',
    ReceiveConfirmation: 'RECEIVE_CONFIRMATION',
    Undefined: 'UNDEFINED'
  };
  RegisterCardRequest.SelectedSectionsEnum = {
    Receive: 'RECEIVE',
    Transferred: 'TRANSFERRED',
    Collected: 'COLLECTED',
    Generated: 'GENERATED'
  };
  RegisterCardRequest.EvidenceTypeEnum = {
    Keo: 'KEO',
    Keok: 'KEOK'
  };
})(RegisterCardRequest || (RegisterCardRequest = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var ReportItemResult;
(function (ReportItemResult) {
  ReportItemResult.StatusEnum = {
    Draft: 'Draft',
    InProgress: 'InProgress',
    Submitted: 'Submitted',
    Confirmed: 'Confirmed',
    Rejected: 'Rejected',
    ForCorrection: 'ForCorrection',
    Corrected: 'Corrected'
  };
})(ReportItemResult || (ReportItemResult = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var SearchCardRequest;
(function (SearchCardRequest) {
  SearchCardRequest.SortTypeEnum = {
    LastModifiedDateDesc: 'SORT_BY_LAST_MODIFIED_DATE_DESC',
    LastLastModifiedDateAsc: 'SORT_BY_LAST_LAST_MODIFIED_DATE_ASC',
    StatusDesc: 'SORT_BY_STATUS_DESC',
    StatusAsc: 'SORT_BY_STATUS_ASC',
    DateTransportAsc: 'SORT_BY_DATE_TRANSPORT_ASC',
    DateTransportDesc: 'SORT_BY_DATE_TRANSPORT_DESC',
    VehicleRegNumberAsc: 'SORT_BY_VEHICLE_REG_NUMBER_ASC',
    VehicleRegNumberDesc: 'SORT_BY_VEHICLE_REG_NUMBER_DESC',
    CardNumberAsc: 'SORT_BY_CARD_NUMBER_ASC',
    CardNumberDesc: 'SORT_BY_CARD_NUMBER_DESC',
    ReceiverNameAsc: 'SORT_BY_RECEIVER_NAME_ASC',
    ReceiverNameDesc: 'SORT_BY_RECEIVER_NAME_DESC',
    SenderNameAsc: 'SORT_BY_SENDER_NAME_ASC',
    SenderNameDesc: 'SORT_BY_SENDER_NAME_DESC',
    WasteCodeAsc: 'SORT_BY_WASTE_CODE_ASC',
    WasteCodeDesc: 'SORT_BY_WASTE_CODE_DESC'
  };
})(SearchCardRequest || (SearchCardRequest = {}));

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Wasterecord Service API
 * Wasterecord Service API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

class ApiModule {
  static forRoot(configurationFactory) {
    return {
      ngModule: ApiModule,
      providers: [{
        provide: Configuration,
        useFactory: configurationFactory
      }]
    };
  }
  constructor(parentModule, http) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575');
    }
  }
  static ɵfac = function ApiModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ApiModule)(i0.ɵɵinject(ApiModule, 12), i0.ɵɵinject(i1.HttpClient, 8));
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ApiModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiModule, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [],
      exports: [],
      providers: []
    }]
  }], () => [{
    type: ApiModule,
    decorators: [{
      type: Optional
    }, {
      type: SkipSelf
    }]
  }, {
    type: i1.HttpClient,
    decorators: [{
      type: Optional
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { APIS, AdditionalReportInfo, ApiModule, BASE_PATH, COLLECTION_FORMATS, CardsSearchParameters, ChangeStatusResult, CommuneControllerService, CommuneTeryt, CompanyControllerService, CompanyItemResult, Configuration, DeleteGeneratedResponse, DictionariesService, DocumentControllerService, EvidenceControllerService, EvidenceEntryResult, EvidenceItem, GenerateNewReportResult, GetCommunesRequest, GetKpokDocumentRequestModel, GetKpokPdfRequest, InitialDataControllerService, KeoControllerService, KeokControllerService, KpoControllerService, KpoDetailsRequest, KpokControllerService, KpokDetailsRequest, ManufacturingCompanyUnit, ManufacturingReportService, ManufacturingSaveEupForSection1ResultItem, ManufacturingSaveTablesDataInfoDto, ManufacturingWasteDto, ProductReportService, RegisterCardRequest, ReportItemResult, SearchCardRequest, SearchControllerService, WasteCodeControllerService, WasteProcessControllerService };
